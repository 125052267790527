<template>
    <div class="card card-default animated-fast fadeInLeft" v-if="tickets.length">
        <div class="card-header text-bold">
            <span v-if="more_tickets">
            <i class="fad fa-comment-dots"></i> {{ $t('support.component.tickets.title_limit', {amount: limit}) }}<small> <router-link :to="{name: 'Support:Tickets'}" class="ml-2">{{ $t('support.component.tickets.button_show_all') }} <i
                class="fad fa-arrow-right ml-2"></i></router-link></small>
            </span>
            <span v-else>
                <i class="fad fa-comment-dots"></i> {{ $t('support.component.tickets.title') }}
            </span>
        </div>
        <div class="card-body">

            <p>{{ $t('support.component.tickets.description') }}</p>

            <router-link v-if="tickets.length" tag="a" :to="{name: 'Support:Ticket', params: {id: ticket.id}}" v-for="ticket in tickets" class="border-bottom p-2 row ticket" :key="ticket.id">
                <b-col class="col-xxl-1 text-nowrap">
                    #{{ ticket.id }}
                </b-col>
                <b-col xl="1" class="text-nowrap">
                    {{ ticket.status }}
                </b-col>
                <b-col lg="5">
                    {{ ticket.title }}
                </b-col>
                <b-col md="4" xl="3" class="text-right">
                    {{ $moment.utc(ticket.created_at).local().format('LLL') }}
                </b-col>
                <b-col sm="1" class="text-right text-primary">
                    <i class="fad fa-arrow-right"></i>
                </b-col>
            </router-link>
        </div>
    </div>
</template>
<style lang="scss" scoped>

    .ticket {
        text-decoration: none;
        color: inherit;

        &:hover {
            background-color: #4b4b4b;
            color: white;
            cursor: pointer;
        }
    }
</style>
<script>
    export default {
        name: 'tickets',
        props: ['limit'],
        data() {
            return {
                tickets: [],
                more_tickets: false
            }
        },
        components: {},
        beforeMount() {
            this.$api.get('support/tickets').then(response => {
                if (response.data.length > this.limit) this.more_tickets = true;
                this.tickets = response.data.slice(0, this.limit);
            });

        },
        methods: {}
    }
</script>